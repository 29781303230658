<template>
  <el-container v-if="showPaper">
    <el-header>
      <el-row>
        <el-col style="border-bottom: 1px solid #f5f5f5">
          <span class="startExam">试卷名字: </span>
          <span style="color: red;font-size: 18px;">{{ examInfo.name }}</span>
          <span class="examTitle">考试时长: </span>
          <span style="color: red;font-size: 18px;">{{ examInfo.duration }} 分钟</span>
          <div v-if="paperStatus === 2">
            <el-button
              type="warning"
              round
              style="background-color: #ffd550;float: right;color: black;font-weight: 800"
              @click="uploadExamToAdmin"
            >提交试卷
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-header>

    <el-main>
      <el-row>
        <el-col :span="16">
          <el-row v-for="(item, index) in questionMap" :key="index">
            <el-row class="card-style">
              <el-card>
                <!-- 题目信息 -->
                <div>
                  <span v-if="item[1].questionType === 1">【单选题】</span>
                  <span v-else-if="item[1].questionType === 2">【多选题】</span>
                  <span v-else-if="item[1].questionType === 3">【不定项选择题】</span>
                  <span v-else-if="item[1].questionType === 4">【判断题】</span>
                  <span v-else-if="item[1].questionType === 5">【填空题】</span>
                  <span v-else-if="item[1].questionType === 6">【问答题】</span>
                  <span v-else-if="item[1].questionType === 7">【理解题】</span>
                  <span v-else>【综合题】</span>
                  <br>
                  <br>
                  <i class="num">{{ item[1].pos }}</i>
                  <span style="color: red">
                    ({{ item[1].score }} 分)
                  </span>
                  <span v-html="item[1].questionContent" />
                  <div v-if="item[1].questionType === 8">
                    <div v-for="(child, childIndex) in item[1].children" :key="childIndex">
                      <div>
                        <i class="num">{{ item[1].pos }}</i>
                        <i class="num">{{ child.pos }}</i>
                        <span style="color: red">
                          ({{ child.score }} 分)
                        </span>
                        <span v-html="child.questionContent" />
                      </div>
                      <!-- 单选和判断题候选答案列表 -->
                      <div
                        v-show="child.questionType === 1 || child.questionType === 4"
                        style="margin-top: 25px"
                      >
                        <el-radio-group
                          v-for="(ans,ans_index) in child.answer"
                          :key="ans_index"
                          v-model="singleChecks[ans.questionId]"
                          @change="singleCheckChange(child)"
                        >
                          <el-radio :label="ans.pos">
                            <span>{{ optionName[ans.pos-1] + '、' + ans.answer }}</span>
                          </el-radio>
                        </el-radio-group>
                      </div>

                      <!-- 多选和不定项选择题的候选答案列表 -->
                      <div
                        v-show="child.questionType === 2 || child.questionType === 3"
                        style="margin-top: 25px"
                      >
                        <el-checkbox-group
                          v-for="(ans,ans_index) in child.answer"
                          :key="ans_index"
                          v-model="multipleChecked[item[1].pos]"
                          @change="multipleCheckChange(child)"
                        >
                          <el-checkbox
                            :label="ans.pos"
                            border
                            style="margin-top: 20px"
                          >
                            <span>{{ optionName[ans.pos-1] + '、' + ans.answer }}</span>
                          </el-checkbox>
                        </el-checkbox-group>
                      </div>

                      <!-- 填空题和问答题的回答区 -->
                      <div
                        v-show="child.questionType === 5 || child.questionType === 6"
                        style="margin-top: 25px"
                      >
                        <el-input
                          v-model="userAnswer[curIndex]"
                          type="textarea"
                          :rows="8"
                          placeholder="请输入答案"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 单选和判断题候选答案列表 -->
                <div
                  v-show="item[1].questionType === 1 || item[1].questionType === 4"
                  style="margin-top: 25px"
                >
                  <el-radio-group
                    v-for="(ans,ans_index) in item[1].answer"
                    :key="ans_index"
                    v-model="singleChecks[ans.questionId]"
                    @change="singleCheckChange(item[1])"
                  >
                    <el-radio :label="ans.pos">
                      <span>{{ optionName[ans.pos-1] + '、' + ans.answer }}</span>
                    </el-radio>
                  </el-radio-group>
                </div>

                <!-- 多选和不定项选择题的候选答案列表 -->
                <div
                  v-show="item[1].questionType === 2 || item[1].questionType === 3"
                  style="margin-top: 25px"
                >
                  <el-checkbox-group
                    v-for="(ans,ans_index) in item[1].answer"
                    :key="ans_index"
                    v-model="multipleChecked[item[1].pos]"
                    @change="multipleCheckChange(item[1])"
                  >
                    <el-checkbox
                      :label="ans.pos"
                      border
                      style="margin-top: 20px;"
                    >
                      <span>{{ optionName[ans.pos-1] + '、' + ans.answer }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>

                <!-- 填空题的回答区 -->
                <div
                  v-show="item[1].questionType === 5"
                  style="margin-top: 25px"
                >
                  <el-input
                    v-model="question5[item[1].questionId]"
                    type="text"
                    placeholder="请输入答案"
                    @blur="onInputBlur(item[1])"
                  />
                </div>
                <!-- 问答题的回答区 -->
                <div
                  v-show="item[1].questionType === 6"
                  style="margin-top: 25px"
                >
                  <el-input
                    v-model="question6[item[1].questionId]"
                    type="textarea"
                    :rows="8"
                    placeholder="请输入答案"
                    @blur="onInputBlur(item[1])"
                  />
                </div>
              </el-card>
            </el-row>
          </el-row>
        </el-col>
        <!-- 答题卡 -->
        <el-col :span="8">
          <el-row class="card-style">
            <el-card>
              <div>
                <p style="font-size: 18px;">答题卡</p>
              </div>
              <!-- 单选的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">单选题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 1"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 1 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>
              <!-- 多选的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">多选题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 2"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                >{{ item }}
                </el-button>
              </div>
              <!-- 不定项选择题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">不定项选择题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 3"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 3 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>

              <!-- 判断题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">判断题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 4"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 4 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>

              <!-- 填空题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">填空题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 5"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 5 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>

              <!-- 问答题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">问答题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 6"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 6 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>

              <!-- 综合题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">综合题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 8"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 8 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                  @click="curIndex = item-1"
                >{{ item }}
                </el-button>
              </div>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import { getPaper, submitExam } from '@/api/exam'

export default {
  name: 'ExamPaperPreview',
  data() {
    return {
      paperStatus: 1,
      // 当前考试的信息
      examInfo: {
        name: '',
        description: '',
        duration: 0,
        totalScore: 0
      },
      // 当前的考试题目
      questionList: [
        {
          questionType: ''
        }
      ],
      // 当前题目的索引值
      curIndex: 0,
      // 控制大图的对话框
      bigImgDialog: false,
      // 当前要展示的大图的url
      bigImgUrl: '',
      // 用户选择的答案
      userAnswer: [],
      // 页面数据加载
      loading: {},
      // 页面绘制是否开始
      showPaper: false,
      // 答案的选项名abcd数据
      optionName: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      // 考试总时长
      duration: 0,
      answer: {
        pos: null,
        questionId: null,
        questionType: null,
        questionAnswer: null,
        children: []
      },
      questionMap: new Map(),
      childMap: new Map(),
      userAnswers: new Map(),
      singleChecks: {},
      multipleCheck: [],
      multipleChecked: [
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], [],
        [], [], [], [], [], [], [], [], [], []
      ],
      multipleChecks: {},
      question5: {},
      question6: {}
    }
  },
  created() {
    const path = this.$route.path
    if (path.startsWith('/exam/preview')) {
      document.title = '试卷预览'
      this.paperStatus = 1
    } else if (path.startsWith('/exam/start')) {
      document.title = '试卷考试'
      this.paperStatus = 2
    }

    const paperId = this.$route.params.paperId
    this.getPaperDeatil(paperId)
  },
  methods: {
    renderByMathjax() {
      // tinymce 的 mathjax 插件生成的 latex 格式公式放在 className 为 math-tex 的 span 标签内
      const className = 'math-tex'
      this.$nextTick(function() {
        if (this.MathJax.isMathjaxConfig) {
          this.MathJax.initMathjaxConfig()
        }
        this.MathJax.MathQueue1(className)
      })
    },
    // 查询当前考试的信息
    getPaperDeatil(paperId) {
      getPaper(paperId).then((resp) => {
        if (resp.code === 0) {
          const respData = resp.data
          this.examInfo.name = respData.name
          this.examInfo.description = respData.description
          this.examInfo.duration = respData.duration
          this.examInfo.totalScore = respData.totalScore

          this.questionList = respData.questions
          // 重置问题的顺序 单选 ->  -> 判断 -> 简答
          this.questionList = this.questionList.sort(function(a, b) {
            return a.questionType - b.questionType
          })

          for (const item of this.questionList) {
            this.questionMap.set(item.questionId, item)
            if (item.questionType === 2 || item.questionType === 3) {
              this.$set(this.multipleChecks, item.questionId, [])
            } else if (item.questionType === 5) {
              this.$set(this.question5, item.questionId, null)
            } else if (item.questionType === 6) {
              this.$set(this.question6, item.questionId, null)
            }

            if (item.children !== undefined && item.children !== null) {
              for (const child of item.children) {
                this.childMap.set(child.questionId, item.questionId)
                this.questionMap.set(item.questionId, item)
                if (child.questionType === 2 || child.questionType === 3) {
                  this.$set(this.multipleChecks, child.questionId, [])
                } else if (child.questionType === 5) {
                  this.$set(this.question5, child.questionId, null)
                } else if (child.questionType === 6) {
                  this.$set(this.question6, child.questionId, null)
                }
              }
            }
          }

          this.showPaper = true
          this.renderByMathjax()
        }
      })
    },
    // ****************************************************************************************************************
    // 单选和判断
    singleCheckChange(val) {
      const questionId = val.questionId
      var pid = this.getPid(questionId)
      const answer = this.singleChecks[questionId]
      this.setUserAnswers(val, answer, pid)
    },
    // 多选和不定项选择题
    multipleCheckChange(val) {
      var questionId = val.questionId
      var pid = this.getPid(questionId)
      var answer = this.multipleChecked[val.pos]
      var answerStr = answer.join(',')
      this.setUserAnswers(val, answerStr, pid)
    },
    // 填空和问答题
    onInputBlur(val) {
      var questionId = val.questionId
      var pid = this.getPid(questionId)
      var questionType = val.questionType
      if (questionType === 5) {
        const answer = this.question5[questionId]
        this.setUserAnswers(val, answer, pid)
      } else if (questionType === 6) {
        const answer = this.question6[questionId]
        this.setUserAnswers(val, answer, pid)
      }
    },
    getPid(questionId) {
      var pid = this.childMap.get(questionId)
      if (pid !== undefined && pid !== null) {
        return pid
      } else {
        return 0
      }
    },
    setUserAnswers(question, answer, pid) {
      const userAnswer = {}
      userAnswer.questionId = question.questionId
      userAnswer.questionType = question.questionType
      userAnswer.pos = question.pos
      userAnswer.answer = answer
      userAnswer.pid = pid
      this.userAnswers.set(userAnswer.questionId, userAnswer)
    },
    async uploadExamToAdmin() {
      for (const item of this.questionMap) {
        const questionId = item[0]
        const question = item[1]
        const questionType = question.questionType
        if (questionType === 8) {
          continue
        }

        const answer = this.userAnswers.get(questionId)
        if (answer === undefined || answer === null) {
          console.log(questionId)
          this.$message.error('试题 ' + question.pos + ' 还未完成')
        } else {
          if (answer.answer === null || answer.answer === '') {
            console.log(questionId)
            this.$message.error('试题 ' + question.pos + ' 答案不能为空')
          }
        }
      }

      var submitAnswers = []
      for (const item of this.userAnswers) {
        submitAnswers.push(item[1])
      }

      const userResult = {}
      userResult.paperId = this.examInfo.examId
      userResult.userAnswers = submitAnswers
      submitExam(userResult).then((resp) => {
        if (resp.code === 0) {
          this.$notify({
            title: 'Tips',
            message: '考试结果已提交',
            type: 'success',
            duration: 2000
          })
          const resultId = resp.data
          this.$router.push('/exam/score')
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-weight: 800;
}

.el-container {
  width: 100%;
  height: 100%;
}

.startExam {
  color: #160f58;
  border-bottom: 4px solid #ffd550;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px
}

.examTitle {
  font-size: 18px;
  color: #cbcacf;
  margin-left: 20px;
  font-weight: 700;
}

.el-radio-group label {
  display: block;
  width: 400px;
  padding: 48px 20px 10px 20px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
  }
}

.el-radio-group label:hover {
  background-color: rgb(245, 247, 250);
}

/*当前选中的答案*/
.active {
  border: 1px solid #1f90ff !important;
  opacity: .5;
}

/*做过的题目的高亮颜色*/
.done {
  background-color: rgb(87, 148, 247);
}

/*未做题目的高亮颜色*/
.noAnswer {
  background-color: rgb(238, 238, 238);
}

/*当前在做的题目高亮的颜色*/
.orange {
  background-color: rgb(255, 213, 80);
}

.num {
  display: inline-block;
  background: url('../../assets/img/examTitle.png') no-repeat 95%;
  background-size: contain;
  height: 37px;
  width: 37px;
  line-height: 30px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-right: 15px;
}

.card-style {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
